<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-430">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">VIEW QR CODE</span>
            </div>
            <div class="general-section" style="padding: 15px 0px;">
            <div class="v-modal-body" id="printId">
                <div class="contentCenter width-fit qrcode">
                    <qr-code
                        class="bcode"
                        :text="selected_table.qr_code_url"
                        :size="170"
                        color="#005dae"
                    ></qr-code>
                </div>
                <div class="contentCenter width-fit mt-4">
                    <span class="fw-500 fs-18">{{selected_table.table_id}}</span>
                </div>
            </div>
            </div>
            <div class="v-modal-footer modal-bg text-center pt-5 pb-5">
                <a class="btn btn-secondary btn-smm text-uppercase ml-1" @click="hideViewQrCode">CLOSE</a>
                <a class="btn btn-success btn-smm text-uppercase ml-1" @click="printWeb()">PRINT</a>
            </div>
        </div>
    </modal>
</template>
<script>
import print from "./mixins/print.js"
export default {
    props:['modal_name','selected_table'],
    data(){
        return{

        }
    },
    mixins:[print],
    methods:{
        hideViewQrCode(){
            this.$modal.hide(this.modal_name)
            this.$emit("hideViewQrCode")
        },
        printQrCode(){

        }
    }
}
</script>
<style scoped>
    .width-fit{
        width: max-content;
    }
    .qrcode{
        background-color: #f5f6f9;
        padding: 15px;
        width: max-content;
    }
    .contentCenter{
        margin: 0 auto;
    }
    .fs-18{
        font-size: 18px;
    }
</style>