export default {
    data(){
        return{
            selected_view: "Web View"
        }
    },
    methods:{
        printWeb: function (printId) {
            const prtHtml = document.getElementById('printId').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
            }

            // Open the print window
            const printDocument = window.open('', '_blank');
            printDocument.document.write(`<!DOCTYPE html>
            <html>
                <head>
                    <title>
                        DigiCollect SCM
                    </title>
                    ${stylesHtml}
                    <style>
                        body{
                            overflow: auto !important;
                            background: #fff !important;
                        }
                    </style>
                </head>
                <body>
                    ${prtHtml}
                </body>
            </html>`);
            setTimeout(() => {
                printDocument.document.close();
                printDocument.focus();
                printDocument.print();
            }, 2000);
            setTimeout(() => {
                printDocument.close();
            }, 3000);
        },
        printPrint: function (printId) {
            const prtHtml = document.getElementById('printId').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
            }

            // Open the print window
            const printDocument = window.open('', '_blank');
            printDocument.document.write(`<!DOCTYPE html>
            <html>
                <head>
                    <title>
                        DigiCollect SCM
                    </title>
                    ${stylesHtml}
                    <style>
                        body{
                            overflow: auto !important;
                            background: #fff !important;
                        }
                    </style>
                </head>
                <body>
                    ${prtHtml}
                </body>
            </html>`);
            setTimeout(() => {
                printDocument.document.close();
                printDocument.focus();
                printDocument.print();
            }, 2000);
            setTimeout(() => {
                printDocument.close();
            }, 3000);
        },
        printPage(){
             if(this.selected_view == 'Web View'){
                 this.printWeb()
             }
             else{
                 this.printPrint()
             }
        },
        selectView(obj){
            if(obj == 'web'){
                this.selected_view = "Web View"
            }
            else{
                this.selected_view = "Print View"
            }
        },
    }
}